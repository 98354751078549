import { IS_TEACHER } from '@constants';
import { E_GAME_MODES } from '@constants/gameConfigs';
import { IWord } from '@dto';
import { useGameContext } from 'context/GameContext';
import React, { memo, useCallback, useMemo } from 'react';
import trans from 'translation';
import { KButton, KContainer, KInput } from 'uikit';

const ReviewingWords = () => {
  const { gameInfo, toggleShowReviewingWords, isTaboo, gameMode } =
    useGameContext();

  const wordText = useMemo(() => {
    switch (gameMode) {
      case E_GAME_MODES.WORD.id:
        return 'common.word';

      case E_GAME_MODES.SENTENCE.id:
        return 'common.sentence';

      case E_GAME_MODES.PARAGRAPH.id:
        return 'common.paragraph';

      default:
        return 'common.word';
    }
  }, [gameMode]);

  const renderWord = useCallback(
    (i: IWord, idx: number) => {
      return (
        <KContainer.View key={i.id} marginB="1.5rem">
          <KInput.TextField
            name={`word.${i.id}-${i.texts?.[0] ?? ''}`}
            label={`${trans(wordText)} ${idx + 1}`}
            value={i.texts?.[0] ?? ''}
            readOnly
          />

          {isTaboo && (
            <KContainer.View row alignItems marginT="0.75rem">
              <KInput.Autocomplete
                name={`bannedWord.${i.id}-${i.texts?.[0] ?? ''}`}
                clearIcon={false}
                freeSolo
                multiple
                options={[]}
                placeholder={trans('placeholder.banned_words')}
                value={i.banneds}
                readOnly
              />
            </KContainer.View>
          )}
        </KContainer.View>
      );
    },
    [isTaboo, wordText]
  );

  return (
    <>
      {(gameInfo?.words ?? []).map((i, idx) => renderWord(i, idx))}

      {IS_TEACHER && (
        <KButton.Solid
          width={'100%'}
          title={trans('common.score_discussion')}
          onPress={toggleShowReviewingWords}
        />
      )}
    </>
  );
};

ReviewingWords.displayName = 'Containers.InGame.Reviewing.Words';

export default memo(ReviewingWords);
