import { IS_TEACHER } from '@constants';
import { ITeamDto } from '@dto';
import { useGameContext } from 'context/GameContext';
import AppAudio from 'core/audio';
import { isNumber } from 'lodash';
import React, { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import trans from 'translation';
import { KButton, KColors, KContainer, KLabel, useMount } from 'uikit';

import { secondsToMinutes } from './helpers';

import InGameImage from '../InGame.Image';
import InGamePackageView from '../InGame.PackageView';

interface ITeamWithIndex extends ITeamDto {
  index: number;
}

const Completed = () => {
  const navigate = useNavigate();

  const { roomInfo, isJumble } = useGameContext();

  const { teams } = roomInfo || {};

  useMount(() => {
    if (IS_TEACHER) {
      AppAudio.playSound('/sounds/leaderboard.mp3');
    }

    return () => {
      if (IS_TEACHER) {
        AppAudio.stopSound();
      }
    };
  });

  const winner = useMemo(() => {
    let _winner: ITeamWithIndex | undefined;
    if (teams) {
      let _score = teams[0].finalScore ?? 0;
      _winner = {
        ...teams[0],
        index: 0
      };

      for (let i = 1; i < teams.length; i++) {
        const score = teams[i].finalScore ?? 0;
        if (score > _score) {
          _score = score;
          _winner = {
            ...teams[i],
            index: i
          };
        }
      }
    }

    return _winner;
  }, [teams]);

  const onOffGame = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const renderTeam = useCallback(
    (team: ITeamDto, index: number) => {
      const { name, completionTime, finalScore } = team;
      return (
        <KContainer.View
          br="4x"
          paddingH="1.5rem"
          paddingV="1.25rem"
          height={92}
          background={KColors.white}
          row
          alignItems
          justifyContent="space-between"
          marginT={index === 0 ? 0 : '0.75rem'}
          key={team.id}
        >
          <KContainer.View row alignItems>
            <KContainer.View
              br="round"
              center
              size={24}
              brC="#E6E6E6"
              brW={1.5}
              marginR="0.75rem"
            >
              <KLabel.Text typo="TextXsNormal">{index + 1}</KLabel.Text>
            </KContainer.View>

            <KLabel.Text typo="TextMdNormal">{name}</KLabel.Text>
          </KContainer.View>

          <KContainer.View row alignItems gap="1rem">
            <KContainer.View
              height={'100%'}
              width={42}
              alignItems
              justifyContent="space-between"
            >
              <KLabel.Paragraph>{trans('common.score')}</KLabel.Paragraph>

              <KLabel.Paragraph typo="TextXLgBold">
                {finalScore}
              </KLabel.Paragraph>
            </KContainer.View>

            {isJumble && isNumber(completionTime) && (
              <KContainer.View
                height={'100%'}
                width={50}
                alignItems
                justifyContent="space-between"
              >
                <KLabel.Paragraph>{trans('common.time')}</KLabel.Paragraph>

                <KLabel.Paragraph typo="TextXLgBold">
                  {completionTime === -1
                    ? '-'
                    : secondsToMinutes(completionTime ?? 0)}
                </KLabel.Paragraph>
              </KContainer.View>
            )}
          </KContainer.View>
        </KContainer.View>
      );
    },
    [isJumble]
  );

  return (
    <KContainer.View alignItems padding="1.5rem">
      <InGameImage uri="game-finished.webp" paddingH={0} paddingV={0} />

      <KContainer.View marginV="1.75rem" alignItems>
        <KLabel.Paragraph
          typo="TextXLgBold"
          textTransform="uppercase"
          color={KColors.primary.normal}
        >
          {trans('common.winner_winner')} {trans('common.chicken_dinner')}
        </KLabel.Paragraph>

        <KLabel.Paragraph
          marginT="0.5rem"
          typo="TextXLgNormal"
          textTransform="uppercase"
          color={KColors.primary.normal}
        >
          {trans('common.team_with_n_point', {
            count: winner?.finalScore ?? 0
          })}
        </KLabel.Paragraph>
      </KContainer.View>

      <InGamePackageView br="4x" brBL={0} brBR={0} kind="secondary">
        {(teams ?? [])
          .sort((a, b) => (b.finalScore ?? 0) - (a.finalScore ?? 0))
          .map((i, idx) => renderTeam(i, idx))}
      </InGamePackageView>

      <KButton.Solid
        marginT="1.5rem"
        width={'100%'}
        title={trans('common.see_you_again')}
        onPress={onOffGame}
      />
    </KContainer.View>
  );
};

Completed.displayName = 'Containers.InGame.Completed';

export default memo(Completed);
