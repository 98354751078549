import { MediaHTMLAttributes } from 'react';

import AppStorage, { STORAGE_KEYS } from './storage';

class AppAudio {
  private audio: HTMLAudioElement | null = null;
  private curSrc: string | null = null;
  private prevSrc: string | null = null;
  private timeoutInstance: NodeJS.Timer | null = null;
  private volume: number =
    AppStorage.get(STORAGE_KEYS.settings)?.volume?.value ?? 1.0;
  private isMuted: boolean =
    AppStorage.get(STORAGE_KEYS.settings)?.volume?.isMuted ?? false;

  // Create a singleton instance
  private static instance: AppAudio;

  private constructor() {}

  public static getInstance(): AppAudio {
    if (!AppAudio.instance) {
      AppAudio.instance = new AppAudio();
    }
    return AppAudio.instance;
  }

  public playSound(
    src: string,
    others?: Omit<MediaHTMLAttributes<HTMLAudioElement>, 'src'> & {
      isRevert?: boolean;
      keepRunningAfterSwitch?: boolean;
      cb?: () => void;
    }
  ) {
    const { loop, isRevert, keepRunningAfterSwitch, cb } = others || {};

    if (src.includes('.mp3')) {
      // Stop the currently playing sound if it exists
      if (this.audio) {
        this.audio.pause();
      }

      this.prevSrc = this.curSrc;

      // Create a new audio instance with loop and autoplay functionality
      this.audio = new Audio(src);
      this.audio.volume = this.volume;
      this.audio.muted = this.isMuted;

      if (loop && !keepRunningAfterSwitch) {
        this.audio.loop = true; // Set to loop
      }

      this.audio
        .play()
        .catch(error => {
          console.error('Failed to play audio:', error);

          if (isRevert && this.prevSrc) {
            this.playSound(this.prevSrc);
          }
        })
        .then(() => {
          if (keepRunningAfterSwitch) {
            if (this.timeoutInstance) {
              clearTimeout(this.timeoutInstance);
            }
            this.timeoutInstance = setTimeout(() => {
              if (this.prevSrc) {
                this.playSound(this.prevSrc);
              }
            }, 5000);
          }

          this.curSrc = src;
        })
        .finally(() => {
          cb?.();
        });
    } else {
      cb?.();
    }
  }

  public stopSound(src?: string) {
    if (this.audio && (src === undefined || this.curSrc === src)) {
      this.audio.pause();
      this.audio = null;
      this.curSrc = null;

      if (this.timeoutInstance) {
        clearTimeout(this.timeoutInstance);
        this.timeoutInstance = null;
      }
    }
  }

  private saveStorage(v: { value?: number; isMuted?: boolean }) {
    const localData = AppStorage.get(STORAGE_KEYS.settings);
    AppStorage.set(STORAGE_KEYS.settings, {
      ...localData,
      volume: { ...localData?.volume, ...v }
    });
  }

  public setVolume(v: number) {
    this.volume = Math.max(0, Math.min(v, 1)); // Ensure volume is between 0.0 and 1.0
    if (this.audio) {
      this.audio.volume = this.volume; // Apply volume to the current audio
    }
    this.saveStorage({ value: this.volume });
  }

  public toggleMute() {
    this.isMuted = !this.isMuted; // Toggle mute state
    if (this.audio) {
      // const newVolume = this.isMuted ? 0 : this.volume;
      // this.audio.volume = newVolume; // Apply mute or current volume
      this.audio.muted = this.isMuted;
    }
    this.saveStorage({ isMuted: this.isMuted });
  }

  public getVolume(): number {
    return this.volume;
  }

  public getIsMuted(): boolean {
    return this.isMuted;
  }
}

// Export a singleton instance
export default AppAudio.getInstance();
