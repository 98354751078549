import { EventName } from '@constants';
import AppEvent from 'core/event';
import { useEffect } from 'react';

export const useAppEvent = (
  event: keyof typeof EventName,
  callback: EventListenerOrEventListenerObject
) => {
  useEffect(() => {
    AppEvent.subscribe(EventName[event], callback);

    return () => {
      AppEvent.unsubscribe(EventName[event], callback);
    };
  }, [callback, event]);
};
