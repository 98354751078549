import { SelectionItem } from '@dto';
import upperFirst from 'lodash/upperFirst';

export enum GAMES {
  TABOO = 'Taboo',
  HOTSEAT = 'Hotseat',
  JUMBLE = 'Jumble',
  CATEGORIES = 'Categories',
  SNAP = 'Snap'
}

export enum TABLE_STATUSES {
  WAITING = 'waiting',
  WAITING_FOR_READY = 'waiting_for_ready',
  PLAYING = 'playing',
  PAUSING = 'pausing',
  REVIEWING = 'reviewing',
  COMPLETED = 'completed',
  TERMINATED = 'terminated'
}

export enum WORD_STATUSES {
  NOT_STARTED_YET = 'not_started_yet',
  RUNNING = 'running',
  TIME_UP = 'time_up',
  COMPLETED = 'completed'
}

export enum STUDENT_STATUSES {
  NOT_READY = 'not_ready',
  READY = 'ready'
}

export const E_RACE_MODES = {
  RACE_NORMAL: {
    id: 3,
    title: 'Race/normal'
  },

  // This mode is used with time limit setting (min/sec)
  TIME_LIMIT_WORD: {
    id: 4,
    title: 'Time limit/word'
  }
};

export const E_GAME_MODES = {
  // It's TAKE_TURN in the past
  // Each team will take turns playing with the teacher
  WHOLE_CLASS: {
    id: 1,
    title: 'Whole class'
  },

  // Each team will be picked to play with another team
  // Every teams will start playing at the same time
  CHAOTIC: {
    id: 2,
    title: 'Chaotic'
  },

  WORD: {
    id: 5,
    title: 'Word'
  },
  SENTENCE: {
    id: 6,
    title: 'Sentence'
  },
  PARAGRAPH: {
    id: 7,
    title: 'Paragraph'
  }
};

const makeItems = (length?: number, specificArray?: number[]) => {
  const arr =
    specificArray || new Array(length).fill(0).map((_, index) => index + 1);
  return arr.map(i => {
    return {
      id: i,
      title: `${i}`
    };
  });
};

export const AVAILABLE_GAMES = <SelectionItem[]>[
  { id: GAMES.TABOO, title: upperFirst(GAMES.TABOO) },
  { id: GAMES.HOTSEAT, title: upperFirst(GAMES.HOTSEAT) },
  { id: GAMES.JUMBLE, title: upperFirst(GAMES.JUMBLE) }
  // { id: GAMES.CATEGORIES, title: upperFirst(GAMES.CATEGORIES) },
  // { id: GAMES.SNAP, title: upperFirst(GAMES.SNAP) }
];

export const TABOO_CONFIGS = {
  RACE_MODES: [E_RACE_MODES.RACE_NORMAL, E_RACE_MODES.TIME_LIMIT_WORD],

  GAME_MODES: [E_GAME_MODES.WHOLE_CLASS, E_GAME_MODES.CHAOTIC],

  MAXIMUM_WORDS: 10,

  MAXIMUM_BANNED_WORDS: 5,

  AVAILABLE_TEAMS: makeItems(undefined, [2, 4, 6]),

  NUMBER_OF_STUDENTS: makeItems(
    undefined,
    Array.from({ length: 19 }, (_, i) => i + 2)
  )
};

export const HOTSEAT_CONFIGS = {
  RACE_MODES: [E_RACE_MODES.TIME_LIMIT_WORD],

  GAME_MODES: [],

  MAXIMUM_WORDS: 10,

  AVAILABLE_TEAMS: makeItems(undefined, [2]),

  NUMBER_OF_STUDENTS: makeItems(
    undefined,
    Array.from({ length: 11 }, (_, i) => i + 2)
  )
};

export const JUMBLE_CONFIGS = {
  RACE_MODES: [E_RACE_MODES.RACE_NORMAL, E_RACE_MODES.TIME_LIMIT_WORD],

  GAME_MODES: [
    E_GAME_MODES.WORD,
    E_GAME_MODES.SENTENCE,
    E_GAME_MODES.PARAGRAPH
  ],

  MAXIMUM_WORDS: 10,

  AVAILABLE_TEAMS: makeItems(10),

  NUMBER_OF_STUDENTS: makeItems(10)
};
