export default class KDims {
  static isSSR = () => typeof window === 'undefined';

  static width = !this.isSSR() ? window.innerWidth : 0;

  static height = !this.isSSR() ? window.innerHeight : 0;

  static updateWindowSize = () => {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
  };

  static getWindowSize = () => {
    if (this.isSSR()) {
      return { width: 0, height: 0 };
    }
    return { width: window.innerWidth, height: window.innerHeight };
  };

  static os = navigator.userAgentData?.platform ?? navigator.platform;

  static isMobile = () => {
    if (this.isSSR()) {
      return false;
    }

    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;

    // Check for mobile devices using the user agent
    const isMobile =
      /Mobi|Android|iPhone|iPad|iPod|Opera Mini|BlackBerry/i.test(userAgent);

    // Check if the screen width is less than 768 pixels
    const isSmallScreen = window.innerWidth < 768;

    // Only consider iPads or tablets as mobile if the screen width is less than 768 pixels
    const isTablet = /iPad|Android(?!.*Mobile)/i.test(userAgent);

    return (isMobile && !(isTablet && !isSmallScreen)) || isSmallScreen;

    // return (
    //   /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
    //     navigator.userAgent
    //   ) ||
    //   /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
    //     navigator.userAgent.slice(0, 4)
    //   ) ||
    //   window.innerWidth < 768
    // );

    return (
      navigator.userAgentData?.mobile ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) ||
      window.innerWidth < 768 ||
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      (navigator as any).msMaxTouchPoints > 0
    );
  };

  static isMac =
    ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'].indexOf(this.os) !==
    -1;

  static isIPad = ['iPad'].indexOf(this.os) !== -1;

  static isIPhone = this.isMobile() && ['iPhone'].indexOf(this.os) !== -1;

  static isAndroidPhone =
    this.isMobile() && /Android/.test(navigator.userAgent);

  static isWin = ['Win32', 'Win64', 'Windows', 'WinCE'].indexOf(this.os) !== -1;

  static getOS = () => {
    if (this.isSSR()) {
      return '';
    }

    if (this.isAndroidPhone) {
      return 'Android';
    }

    if (this.isIPad) {
      return 'iPadOS';
    }

    if (this.isIPhone) {
      return 'iOS';
    }

    if (this.isMac) {
      return 'MacOS';
    }

    if (this.isWin) {
      return 'Windows';
    }
  };

  static getIOSVersion = () => {
    if (this.isSSR()) {
      return undefined;
    }

    // if current device is working on iOS, return the version as number,
    // otherwise return undefined
    if (this.isIPhone) {
      const versionInfo: RegExpMatchArray | null = navigator.userAgent.match(
        /iPhone OS ([0-9]+([_][0-9]*)?)/gi // since the userAgent for iPhone shows 'iPhone OS 15_5'
      );

      if (versionInfo !== null) {
        return parseFloat(versionInfo[0].replace('_', '.').split(' ')[2]);
      }
    }

    return undefined;
  };

  static iOSVersion = this.getIOSVersion();

  static isIOSFrom13 = this.iOSVersion ? this.iOSVersion >= 13 : false;

  static FORM_ITEM_HEIGHT = 50;
}
