import { IS_TEACHER } from '@constants';
import { useGameContext } from 'context/GameContext';
import { useStudentInGameInfo } from 'hooks';
import React, { memo } from 'react';
import trans from 'translation';
import { KButton, KColors, KContainer, KDims, KLabel } from 'uikit';

import { useGainLosePoint, useSubmitGainLosePoint } from '../helpers';
import InGameImage from '../InGame.Image';

const TimesUp = () => {
  const { onEmergencyEnd, isJumble, isHotseat, roomInfo } = useGameContext();

  const { teams = [] } = roomInfo || {};

  const { onGainLosePoint } = useGainLosePoint();

  const { onSubmitNoPoint } = useSubmitGainLosePoint();

  const { isSupervisor } = useStudentInGameInfo();

  const isMobile = KDims.isMobile();

  const showButton = !isJumble && (IS_TEACHER || isSupervisor);

  return (
    <KContainer.View alignItems padding="1.5rem">
      <InGameImage
        uri="times-up.webp"
        background="#8571FF"
        justifyContent="center"
      >
        <KLabel.Paragraph
          typo="TextXLgBold"
          color={KColors.white}
          marginT="1.25rem"
        >
          {trans('common.times_up')}
        </KLabel.Paragraph>

        {!isMobile && showButton && (
          <>
            <KButton.Outline
              marginT="3rem"
              width={'100%'}
              title={trans('common.gain_or_lose_a_point')}
              onPress={() => onGainLosePoint()}
            />

            {isHotseat && (
              <KButton.Outline
                marginT="1.5rem"
                width={'100%'}
                title={trans('common.next_word')}
                onPress={() => onSubmitNoPoint(teams?.[0]?.id)}
              />
            )}
          </>
        )}
      </InGameImage>

      {isMobile && showButton && (
        <>
          <KButton.Outline
            marginT="1.5rem"
            width={'100%'}
            title={trans('common.gain_or_lose_a_point')}
            onPress={() => onGainLosePoint()}
          />

          {isHotseat && (
            <KButton.Outline
              marginT="1.5rem"
              width={'100%'}
              title={trans('common.next_word')}
              onPress={() => onSubmitNoPoint(teams?.[0]?.id)}
            />
          )}
        </>
      )}

      {IS_TEACHER && (
        <KButton.Solid
          marginT="1.5rem"
          width={'100%'}
          title={trans(
            isJumble ? 'common.show_student_answer' : 'common.emergency_end'
          )}
          onPress={() => onEmergencyEnd(false)}
        />
      )}
    </KContainer.View>
  );
};

TimesUp.displayName = 'Containers.InGame.TimesUp';

export default memo(TimesUp);
