import { GAMES } from '@constants/gameConfigs';
import { useGameContext } from 'context/GameContext';
import React, { memo, useMemo } from 'react';

import Jumble from './StudentPlaying.Jumble';
import Taboo from './StudentPlaying.Taboo';

const StudentPlaying = () => {
  const { gameInfo } = useGameContext();

  const type = gameInfo?.type ?? GAMES.TABOO;

  const renderContent = useMemo(() => {
    switch (type) {
      case GAMES.JUMBLE:
        return <Jumble />;

      default:
        return <Taboo />;
    }
  }, [type]);

  return <>{renderContent}</>;
};

StudentPlaying.displayName = 'Containers.InGame.StudentPlaying';

export default memo(StudentPlaying);
