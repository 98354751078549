import { IconCheck } from '@assets/icons/components/IconCheck';
import { IconPencil } from '@assets/icons/components/IconPencil';
import { IconUserRight } from '@assets/icons/components/IconUserRight';
import { IconX } from '@assets/icons/components/IconX';
import { IS_TEACHER } from '@constants';
import { IStudentDto } from '@dto';
import loadable from '@loadable/component';
import { useGameContext } from 'context/GameContext';
import AppSignalR, { SIGNALR_EVENTS } from 'core/signalR';
import { useBoolean } from 'hooks/core/useBoolean';
import React, { memo, useCallback, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import trans from 'translation';
import { KColors, KContainer, KInput, KLabel } from 'uikit';
import { UIUtils } from 'utils';

import { IFormData } from './helpers';

const MoveStudent = loadable(() => import('./components/MoveStudent'));

interface IProps {
  item: IStudentDto;
  index: number;
  teamIndex: number;
  isTeacher?: boolean;
}

const StudentItem = ({ item, index }: IProps) => {
  const { roomInfo } = useGameContext();

  const methods = useFormContext<IFormData>();

  const [teams, users, numberOfStudents] = useWatch({
    control: methods.control,
    name: ['teams', 'users', 'numberOfStudents']
  });

  const { id: userId, teamId } = item;

  const [name, setName] = useState<string>(item.username ?? '');
  const [newName, setNewName] = useState<string>(item.username ?? '');

  const { value: isView, toggle } = useBoolean(true);

  const onSubmitMoveStudent = useCallback(
    (_teamId?: string) => {
      if (_teamId === teamId) {
        return;
      }

      const user = users.find(i => i.id === userId);
      if (user) {
        if (_teamId) {
          methods.setValue(`users.${index}.teamId`, _teamId);
          AppSignalR.emit(SIGNALR_EVENTS.UPDATE_ROOM_SETTINGS, {
            ...roomInfo,
            users: (roomInfo?.users ?? []).map(i => {
              if (i.id === userId) {
                i.teamId = _teamId;
              }
              return i;
            })
          });
        } else {
          user.teamId = null;
          AppSignalR.emit(SIGNALR_EVENTS.UPDATE_ROOM_SETTINGS, {
            ...roomInfo,
            users: (roomInfo?.users ?? []).filter(i => i.id !== userId),
            observations: (roomInfo?.observations ?? []).concat([user])
          });
        }
      }
    },
    [index, methods, roomInfo, teamId, userId, users]
  );

  const onMoveStudent = useCallback(() => {
    UIUtils.popup.open({
      withXIcon: true,
      headIcon: {
        jsx: (
          <IconUserRight stroke={KColors.primary.normal} fill={KColors.white} />
        )
      },
      maxWidth: 'xs',
      title: trans('common.move_to_team'),
      content: (
        <MoveStudent
          teamId={teamId}
          teams={teams.filter(
            t => users.filter(u => t.id === u.teamId).length < numberOfStudents
          )}
          onSubmit={onSubmitMoveStudent}
        />
      )
    });
  }, [numberOfStudents, onSubmitMoveStudent, teamId, teams, users]);

  return (
    <KContainer.View row alignItems marginT="1rem" gap="0.75rem">
      <KContainer.View
        br="round"
        size={50}
        brW={1}
        brC={'#ffc746'}
        padding="0.25rem"
        center
      >
        <KContainer.View
          br="round"
          width="100%"
          height="100%"
          background="#fbe8a8"
        />
      </KContainer.View>

      <KContainer.View flex>
        {isView ? (
          <KLabel.Text color={KColors.highlight.normal} typo="TextMdNormal">
            {name}
          </KLabel.Text>
        ) : (
          <KInput.TextField
            name="studentName"
            value={newName}
            onChange={e => setNewName(e.target.value)}
          />
        )}
      </KContainer.View>

      {IS_TEACHER && (
        <>
          <KContainer.Touchable
            br="round"
            size={36}
            background={isView ? KColors.secondary.normal : KColors.white}
            onPress={() => {
              if (!isView && newName !== name) {
                const isNameExist = users.find(
                  i => i.id !== userId && i.username === newName
                );
                if (!isNameExist) {
                  methods.setValue(`users.${index}.username`, newName);
                  AppSignalR.emit(SIGNALR_EVENTS.UPDATE_ROOM_SETTINGS, {
                    ...roomInfo,
                    users: (roomInfo?.users ?? []).map(i => {
                      if (i.id === userId) {
                        i.username = newName;
                      }
                      return i;
                    })
                  });
                  setName(newName);
                } else {
                  UIUtils.snackBar.open({
                    message: 'There is already a user with this username',
                    status: 'danger'
                  });
                  return;
                }
              }
              toggle();
            }}
            center
          >
            {isView ? (
              <IconPencil stroke={KColors.primary.normal} />
            ) : (
              <IconCheck stroke={KColors.primary.normal} fill={KColors.white} />
            )}
          </KContainer.Touchable>

          {!isView && (
            <KContainer.Touchable
              br="round"
              size={36}
              background={KColors.white}
              onPress={() => {
                toggle();
                setNewName(name);
              }}
              center
            >
              <IconX
                stroke={KColors.primary.normal}
                fill={KColors.primary.normal}
              />
            </KContainer.Touchable>
          )}

          <KContainer.Touchable
            br="round"
            size={36}
            background={KColors.secondary.normal}
            onPress={() => onMoveStudent()}
            center
          >
            <IconUserRight
              stroke={KColors.primary.normal}
              fill={KColors.white}
            />
          </KContainer.Touchable>
        </>
      )}
    </KContainer.View>
  );
};

export default memo(StudentItem);
