import { ENDPOINTS, IS_TEACHER, MUTATION_KEYS, QUERY_KEYS } from '@constants';
import { E_GAME_MODES, WORD_STATUSES } from '@constants/gameConfigs';
import { BaseResponse, IRequestJoinRoomDto, IRoomDto } from '@dto';
import { IRequestJoinRoomResponse } from '@response-dto';
import APIManager from '@services';
import { useGameContext } from 'context/GameContext';
import set from 'lodash/set';
import { useNavigate } from 'react-router-dom';
import { UIUtils } from 'utils';

import {
  useStudentInfo,
  useSetStudentInfo,
  useCurrentAuth,
  useUser
} from '../auth';
import { useMutationEnhancer, useQueryEnhancer } from '../core';

export const useCreateRoom = () => {
  return useMutationEnhancer<IRoomDto, number>({
    mutationFn: async gameSetupId => {
      const res = await APIManager.request({
        url: ENDPOINTS.gameRoom('CreateRoom'),
        method: 'POST',
        body: {
          gameSetupId
        }
      });
      return res.data;
    }
  });
};

export const useFetchRoom = (
  id?: string | number,
  onSuccess?: (data: any) => void
) => {
  const navigate = useNavigate();

  return useQueryEnhancer<IRoomDto>({
    queryKey: [QUERY_KEYS.room, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.gameRoom('GetRoom'),
        body: {
          id
        }
      });

      if (res.data) {
        const { gameSetup, ...data } = res.data;
        const newData = {
          ...data,
          gameSetup: {
            ...gameSetup,
            raceMode: gameSetup?.roleTime,
            gameMode: gameSetup?.rolePlay
          }
        };
        set(res, 'data', newData);
      }

      return res;
    },
    enabled: !!id,
    // refetchInterval: 5000,
    onSuccess: data => {
      onSuccess?.(data);
    },
    onError: (e: any) => {
      // console.log('e', e.response);
      if (e?.response?.data?.code === 404) {
        navigate('/');
      }
    }
  });
};

export const useFetchActiveRoomList = () => {
  const { user } = useUser();

  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.activeRoomList],
    queryFn: () =>
      APIManager.request({
        url: ENDPOINTS.gameRoom('GetActiveRooms')
      }),
    enabled: !!user,
    initialData: [],
    placeholderData: []
  });
};

export const useTerminateRoom = () => {
  return useMutationEnhancer<any, string>({
    mutationFn: id =>
      APIManager.request({
        url: ENDPOINTS.gameRoom('TerminateRoom'),
        method: 'POST',
        body: {
          id
        },
        showToast: true
      }),
    mutationKey: [MUTATION_KEYS.terminateRoom],
    invalidateQueryKeys: [[QUERY_KEYS.activeRoomList]],
    onSuccess: data => {
      if (data.success) {
        UIUtils.popup.dismiss();
      }
    }
  });
};

export const useRequestJoinRoom = () => {
  const setStudentInfo = useSetStudentInfo();
  const navigate = useNavigate();

  return useMutationEnhancer<BaseResponse<IRequestJoinRoomResponse>>({
    mutationFn: (body: IRequestJoinRoomDto) =>
      APIManager.request({
        url: ENDPOINTS.gameRoom('JoinRoom'),
        method: 'POST',
        body,
        configs: {
          // timeout: 10000
        }
      }),
    onSuccess: (response, variables: IRequestJoinRoomDto) => {
      const data = response.data as IRequestJoinRoomResponse;
      setStudentInfo(data.studentInfo);
      navigate(`/room/${variables.roomCode}`);
    }
  });
};

export const useRequestReconnectRoom = () => {
  const setStudentInfo = useSetStudentInfo();
  const navigate = useNavigate();

  return useMutationEnhancer<BaseResponse<IRequestJoinRoomResponse>>({
    mutationFn: (body: IRequestJoinRoomDto) =>
      APIManager.request({
        url: ENDPOINTS.gameRoom('Reconnect'),
        method: 'POST',
        body,
        configs: {
          // timeout: 10000
        }
      }),
    onSuccess: (response, variables: IRequestJoinRoomDto) => {
      const data = response.data as IRequestJoinRoomResponse;
      setStudentInfo(data.studentInfo);
      navigate(`/room/${variables.roomCode}`);
    }
  });
};

export const useRoomInfo = () => {
  return useGameContext().roomInfo || ({} as IRoomDto);
};

export const useIsDescriber = () => {
  const { teamId, id } = useStudentInfo();
  const roomInfo = useRoomInfo();
  const { teams = [] } = roomInfo;

  // Find my team
  const team = teams.find(i => i.id === teamId);

  if (!team) {
    return false;
  }

  // Find the playing word of my team
  const playingWord = team.words?.find(i => i.status === WORD_STATUSES.RUNNING);

  if (!playingWord) {
    return false;
  }

  const { describerId } = playingWord;

  return describerId === id;
};

export const useIsSupervisor = () => {
  const { teamId } = useStudentInfo();

  const { teams, gameSetup } = useRoomInfo() || {};

  const opposingTeam = (teams ?? []).find(i => i.id !== teamId);

  if (gameSetup?.gameMode === E_GAME_MODES.WHOLE_CLASS.id) {
    return IS_TEACHER;
  }

  // The opposing team is playing a word
  if (
    opposingTeam &&
    (opposingTeam.words ?? []).some(w => w.status === WORD_STATUSES.RUNNING)
  ) {
    return true;
  }

  // My team is playing
  // Or the is not any team is playing
  return false;
};

export const useIsPauseRequestor = () => {
  const currentAuth = useCurrentAuth();

  return currentAuth?.id === '1';
};
