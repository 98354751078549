import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

class Prototype {
  string = {
    trimSpaces: (v: string) => v.replace(/\s/g, ''),

    markdown: (v: string) => (
      <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
        {v}
      </Markdown>
    )
  };

  number = {};

  date = {};

  core = {
    isNullOrUndefined: (v: any) =>
      v === null || v === undefined || v === 'undefined'
  };
}

export default new Prototype();
