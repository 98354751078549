import React, { memo } from 'react';
import {
  KContainer,
  KDims,
  KLabel,
  MarginModifiers,
  generateElementId
} from 'uikit';

export interface FormItemProps {
  id?: string;
  label: React.ReactNode | string;
  subLabel?: string;
  jsx: React.ReactElement;
  marginT?: MarginModifiers['marginT'];
  ignoreContentHeight?: boolean;
  color?: string;
}

const EL_TAG = 'form-item';

const FormItem = ({
  id,
  label,
  jsx,
  marginT = '1.25rem',
  ignoreContentHeight,
  subLabel,
  color
}: FormItemProps) => {
  return (
    <KContainer.View marginT={marginT} id={generateElementId(id, EL_TAG)}>
      <KLabel.Paragraph typo="TextNmBold" marginB={'0.25rem'} color={color}>
        {label}
        {subLabel ? <KLabel.Text>{` ${subLabel}`}</KLabel.Text> : null}
      </KLabel.Paragraph>

      <KContainer.View
        height={ignoreContentHeight ? undefined : KDims.FORM_ITEM_HEIGHT}
        justifyContent
      >
        {jsx}
      </KContainer.View>
    </KContainer.View>
  );
};

(FormItem as React.ComponentType<any>).displayName = EL_TAG;

export default memo(FormItem);
