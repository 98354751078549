import { E_GAME_MODES, GAMES, WORD_STATUSES } from '@constants/gameConfigs';
import { IRoomDto } from '@dto';
import { useCurrentAuth } from 'hooks/auth';
import { useAppEvent } from 'hooks/core';
import { useCallback, useState } from 'react';

export const useRemainingTimeChanged = () => {
  const [time, setTime] = useState<number | undefined>(undefined);

  const { id: myId } = useCurrentAuth();

  const onRemainingTimeChanged = useCallback(
    ({ detail: roomInfo }: { detail: IRoomDto }) => {
      const { teams, users, gameSetup, tables } = roomInfo || {};

      if (gameSetup?.type === GAMES.JUMBLE) {
        const table = tables?.[0];

        if (table) {
          setTime(table.remainingTime);
        }
      } else if (gameSetup?.rolePlay === E_GAME_MODES.CHAOTIC.id) {
        const myProfile = users.find(i => i.id === myId);

        const myTeam = teams.find(i => i.id === myProfile?.teamId);

        const opposingTeam = teams.find(
          i => i.id !== myTeam?.id && i.tableId === myTeam?.tableId
        );

        const myRunningWord = myTeam?.words?.find(
          i => i.status === WORD_STATUSES.RUNNING
        );
        const opposingRunningWord = opposingTeam?.words?.find(
          i => i.status === WORD_STATUSES.RUNNING
        );

        if (myRunningWord || opposingRunningWord) {
          setTime(
            myRunningWord?.remainingTime ?? opposingRunningWord?.remainingTime
          );
        }
      } else if (
        gameSetup?.type === GAMES.HOTSEAT ||
        gameSetup?.rolePlay === E_GAME_MODES.WHOLE_CLASS.id
      ) {
        const runningTeam = teams.find(i => i.isPlaying);

        const runningWord = runningTeam?.words?.find(
          i =>
            i.status === WORD_STATUSES.RUNNING ||
            i.status === WORD_STATUSES.TIME_UP
        );

        if (runningWord) {
          setTime(runningWord.remainingTime);
        }
      }
    },
    [myId]
  );

  useAppEvent('WORD_REMAINING_TIME_CHANGED', onRemainingTimeChanged as any);

  // useEffect(() => {
  //   AppEvent.subscribe(
  //     EventName.WORD_REMAINING_TIME_CHANGED,
  //     onRemainingTimeChanged as any
  //   );

  //   return () => {
  //     AppEvent.unsubscribe(
  //       EventName.WORD_REMAINING_TIME_CHANGED,
  //       onRemainingTimeChanged as any
  //     );
  //   };
  // }, [onRemainingTimeChanged]);

  return time;
};
