export const STORAGE_KEYS = {
  preferences: 's.preferences',
  user: 's.user',
  settings: 's.settings'
};

class AppStorage {
  set = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  get = (key: string) => {
    const v = localStorage.getItem(key);
    try {
      return v ? JSON.parse(v) : undefined;
    } catch (error) {
      return undefined;
    }
  };

  delete = (key: string) => {
    localStorage.removeItem(key);
  };

  clear = () => {
    localStorage.clear();
  };
}

export default new AppStorage();
