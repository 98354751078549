import { IconCheck } from '@assets/icons/components/IconCheck';
import { IconCoinNumber } from '@assets/icons/components/IconCoinNumber';
import { GAMES } from '@constants/gameConfigs';
import { useGameContext } from 'context/GameContext';
import AppAudio from 'core/audio';
import { useCallback } from 'react';
import trans from 'translation';
import { KColors } from 'uikit';
import { UIUtils } from 'utils';

export const useSubmitGainLosePoint = () => {
  const { onRequestUpdateScore } = useGameContext();

  const onSubmit = useCallback(
    (pt: number, teamId?: string) => {
      if (pt !== 0) {
        AppAudio.playSound(
          `/sounds/${pt > 0 ? 'gain_point' : 'lose_point'}.mp3`,
          {
            cb: () => onRequestUpdateScore(pt, teamId)
          }
        );
      } else {
        onRequestUpdateScore(pt, teamId);
      }
    },
    [onRequestUpdateScore]
  );

  const onSubmitGainLosePoint = useCallback(
    (pt: number, teamId?: string) => {
      UIUtils.popup.dismiss();
      UIUtils.popup.open({
        withXIcon: true,
        headIcon: {
          jsx: (
            <IconCheck
              stroke={KColors.primary.normal}
              fill={KColors.transparent}
              width={23}
              height={(23 * 14) / 15}
            />
          )
        },
        maxWidth: 'xs',
        title: trans('common.confirmation'),
        content: trans('common.submit_gain_lose_message'),
        buttons: [
          {
            variant: 'outline',
            title: trans('common.cancel')
          },
          {
            key: 'main',
            variant: 'solid',
            title: trans('common.yes'),
            onPress: () => onSubmit(pt, teamId),
            withDismiss: true
          }
        ]
      });
    },
    [onSubmit]
  );

  const onSubmitNoPoint = useCallback(
    (teamId?: string) => {
      UIUtils.popup.dismiss();
      UIUtils.popup.open({
        withXIcon: true,
        headIcon: {
          jsx: (
            <IconCheck
              stroke={KColors.primary.normal}
              fill={KColors.transparent}
              width={23}
              height={(23 * 14) / 15}
            />
          )
        },
        maxWidth: 'xs',
        title: trans('common.confirmation'),
        content: trans('common.submit_no_point_message'),
        buttons: [
          {
            variant: 'outline',
            title: trans('common.cancel')
          },
          {
            key: 'main',
            variant: 'solid',
            title: trans('common.yes'),
            onPress: () => onSubmit(0, teamId),
            withDismiss: true
          }
        ]
      });
    },
    [onSubmit]
  );

  return { onSubmitGainLosePoint, onSubmitNoPoint };
};

export const useGainLosePoint = () => {
  const { roomInfo, gameInfo } = useGameContext();

  const { teams = [] } = roomInfo || {};

  const { onSubmitGainLosePoint } = useSubmitGainLosePoint();

  const onGainLosePoint = useCallback(
    (teamId?: string) => {
      UIUtils.popup.open({
        withXIcon: true,
        headIcon: {
          jsx: (
            <IconCoinNumber
              stroke={KColors.primary.normal}
              fill={KColors.transparent}
            />
          )
        },
        maxWidth: 'xs',
        title: trans('common.gain_lose_a_point'),
        content: trans('common.gain_lose_message'),
        buttons: [
          {
            key: 'main',
            variant: 'outline',
            title: `+ ${trans('common.gain')}`,
            onPress: () => onSubmitGainLosePoint(1, teamId)
          },
          {
            key: 'main',
            variant: 'outline',
            title: `- ${trans('common.lose')}`,
            onPress: () => onSubmitGainLosePoint(-1, teamId)
          }
        ]
      });
    },
    [onSubmitGainLosePoint]
  );

  const onChooseTeamForPoint = useCallback(() => {
    UIUtils.popup.open({
      withXIcon: true,
      headIcon: {
        jsx: (
          <IconCoinNumber
            stroke={KColors.primary.normal}
            fill={KColors.transparent}
          />
        )
      },
      maxWidth: 'xs',
      title: trans('common.choose_team_for_point'),
      content: trans('common.choose_team_for_point_message'),
      buttons: [
        {
          key: 'main',
          variant: 'outline',
          title: teams?.[0]?.name ?? 'Team 1',
          onPress: () => onGainLosePoint(teams?.[0]?.id)
        },
        {
          key: 'main',
          variant: 'outline',
          title: teams?.[1]?.name ?? 'Team 2',
          onPress: () => onGainLosePoint(teams?.[1]?.id)
        }
      ]
    });
  }, [onGainLosePoint, teams]);

  return {
    onGainLosePoint:
      gameInfo?.type === GAMES.HOTSEAT ? onChooseTeamForPoint : onGainLosePoint
  };
};
