import { IS_TEACHER } from '@constants';
import { useGameContext } from 'context/GameContext';
import React, { memo } from 'react';
import trans from 'translation';
import { KColors, KContainer, KDims, KLabel } from 'uikit';

import ReviewingJumble from './Reviewing.Jumble';
import Team from './Reviewing.Team';
import Words from './Reviewing.Words';

import InGameImage from '../InGame.Image';

const Reviewing = () => {
  const { showReviewingWords, showJumbleStatistics, isJumble } =
    useGameContext();

  const isMobile = KDims.isMobile();

  if (IS_TEACHER) {
    if (isJumble && showJumbleStatistics) {
      return <ReviewingJumble />;
    }

    return (
      <KContainer.View
        paddingV="1.5rem"
        paddingH={isMobile ? '1.5rem' : undefined}
      >
        {showReviewingWords ? <Words /> : <Team />}
      </KContainer.View>
    );
  }

  return (
    <KContainer.View alignItems padding="1.5rem">
      <InGameImage
        uri="waiting-for-review.webp"
        background="#7E9FCC"
        justifyContent="center"
      >
        <KLabel.Paragraph
          typo="TextXLgBold"
          color={KColors.white}
          marginT="1.25rem"
        >
          {trans('common.waiting_for_result_confirmation')}
        </KLabel.Paragraph>
      </InGameImage>
    </KContainer.View>
  );
};

Reviewing.displayName = 'Containers.InGame.Reviewing';

export default memo(Reviewing);
