import { useUser } from 'hooks';
import React, { memo } from 'react';

import AuthDashboard from './Dashboard.Auth';
import NoAuthDashboard from './Dashboard.NoAuth';

const Main = () => {
  const { user } = useUser();
  if (!user) {
    return <NoAuthDashboard />;
  }

  return <AuthDashboard />;
};

export default memo(Main);
