import { PopupTransitionConfigs } from '@constants/transitions';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import { TransitionProps } from '@mui/material/transitions';
import { useMutationState } from '@tanstack/react-query';
import { WithPopupProps } from '@ui';
import { useBoolean } from 'hooks/core/useBoolean';
import { usePopupIcon } from 'hooks/useIcon';
import isEmpty from 'lodash/isEmpty';
import React, {
  forwardRef,
  memo,
  useId,
  useImperativeHandle,
  useState
} from 'react';
import { KButton, KButtonProps, KContainer, KLabel, KPopupProps } from 'uikit';

const { Element, props: elementProps } = PopupTransitionConfigs;

const DEFAULT_KIND: KButtonProps['kind'] = 'primary';
const DEFAULT_SIZE: KButtonProps['size'] = 'md';
const DEFAULT_WEIGHT: KButtonProps['weight'] = 'normal';

export const TransitionComponent = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Element {...elementProps} ref={ref} {...props} />;
});

const PaperComponent = memo((props: PaperProps) => {
  return (
    <Paper
      {...props}
      sx={{ overflowY: 'visible !important', borderRadius: '8px' }}
    />
  );
});

export interface DialogTitleProps
  extends Pick<KPopupProps, 'withXIcon' | 'xIcon' | 'headIcon'> {
  id: string;
  children?: React.ReactNode;
}

const KDialogTitle = memo((props: DialogTitleProps) => {
  const { children, withXIcon, xIcon: _xIcon, headIcon, ...other } = props;

  const { xIcon, popupIcon } = usePopupIcon();

  return (
    <DialogTitle
      sx={{ m: 0, p: '16px', pb: '30px', pt: '48px', cursor: 'move' }}
      {...other}
    >
      {children}

      {withXIcon && xIcon(_xIcon)}

      {headIcon && popupIcon(headIcon)}
    </DialogTitle>
  );
});

const KPopup = forwardRef<WithPopupProps>((_, ref) => {
  const { value, setTrue, setFalse } = useBoolean(false);

  const [data, setData] = useState<KPopupProps | undefined>();

  const {
    id: itemId,
    titleProps,
    title,
    content,
    withXIcon,
    headIcon,
    touchOutsideToDismiss,
    buttons = [],
    mutationKey,
    ...rest
  } = data || {};

  const { color, typo } = titleProps || {};

  const randomId = useId();

  const id = (itemId || randomId).replace(/:/g, '');

  const status = useMutationState({
    filters: { mutationKey },
    select: mutation => mutation.state.status
  });

  useImperativeHandle(
    ref,
    () => ({
      open: (payload: KPopupProps) => {
        setData(payload);
        setTrue();
      },
      dismiss: setFalse,
      dismissById: (_id: string) => {
        if (!!_id && _id === itemId) {
          setFalse();
        }
      }
    }),
    [itemId, setFalse, setTrue]
  );

  if (!id) {
    return null;
  }

  return (
    <Dialog
      key={id}
      aria-labelledby={`popup-title-${id}`}
      maxWidth="sm"
      fullWidth
      {...rest}
      open={value}
      TransitionComponent={TransitionComponent}
      PaperComponent={PaperComponent}
      onClose={touchOutsideToDismiss ? setFalse : undefined}
    >
      <KDialogTitle
        id={`popup-title-${id}`}
        withXIcon={withXIcon}
        headIcon={headIcon}
        xIcon={{ disabled: !!status?.[0] && status?.[0] === 'pending' }}
      >
        {title && (
          <KContainer.View center>
            <KLabel.Text typo={typo || 'H2'} color={color} textAlign>
              {title}
            </KLabel.Text>
          </KContainer.View>
        )}
      </KDialogTitle>

      <DialogContent>
        {typeof content === 'string' ? (
          <KContainer.View dp="flex" alignItems>
            <KLabel.Text textAlign>{content}</KLabel.Text>
          </KContainer.View>
        ) : typeof content === 'function' ? (
          content(setFalse)
        ) : (
          content
        )}
      </DialogContent>

      {!isEmpty(buttons) && (
        <DialogActions>
          <KContainer.View row alignItems gap="1.5rem" width="100%">
            {buttons.map((button, idx) => {
              const {
                size = DEFAULT_SIZE,
                kind = DEFAULT_KIND,
                weight = DEFAULT_WEIGHT,
                variant,
                key,
                ...btn
              } = button;

              const onPress = (e?: any) => {
                button.onPress?.(e);
                if (!button.onPress || button.withDismiss) {
                  setFalse();
                }
              };

              const Wrapper =
                variant === 'outline'
                  ? KButton.Outline
                  : variant === 'solid'
                  ? KButton.Solid
                  : KButton.Transparent;

              return (
                <Wrapper
                  key={`popup-button-${idx}`}
                  isLoading={
                    key === 'main' && !!status?.[0] && status?.[0] === 'pending'
                  }
                  disabled={
                    key !== 'main' && !!status?.[0] && status?.[0] === 'pending'
                  }
                  {...btn}
                  onPress={onPress}
                  size={size}
                  weight={weight}
                  kind={kind}
                  flex
                  stretch
                />
              );
            })}
          </KContainer.View>
        </DialogActions>
      )}
    </Dialog>
  );
});

export default memo(KPopup);
