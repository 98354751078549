export enum EventName {
  LOGOUT = 'logout',
  WORD_REMAINING_TIME_CHANGED = 'word_remaining_time_changed',
  DISCONNECT = 'disconnect',
  ON_SOCKET_RECEIVED_ERROR = 'on_socket_received_error',
  ON_SOCKET_RECEIVED_WARNING = 'on_socket_received_warning'
}

export enum Status {}

export enum ROLE {
  TEACHER = 'teacher',
  STUDENT = 'student'
}

export enum STUDENT_ROLE {
  GUESSER = 'guesser',
  DESCRIBER = 'describer',
  SUPERVISOR = 'supervisor'
}
