export const secondsToMinutes = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const minutesDigits = String(minutes).padStart(2, '0');
  const secondsDigits = String(remainingSeconds).padStart(2, '0');

  // const minutesUnit = minutesDigits === '01' ? 'min' : 'mins';
  // const secondsUnit = minutesDigits === '01' ? 'sec' : 'secs';

  return `${minutesDigits}:${secondsDigits}`;
};
