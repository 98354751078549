import { E_GAME_MODES, TABLE_STATUSES } from '@constants/gameConfigs';
import Completed from 'containers/InGame/Completed';
import Pausing from 'containers/InGame/Pausing';
import Playing from 'containers/InGame/Playing';
import Reviewing from 'containers/InGame/Reviewing';
import WaitingForStart from 'containers/InGame/WaitingForStart';
import WaitingRoom from 'containers/InGame/WaitingRoom';
import PageHeader from 'containers/PageHeader';
import { useGameContext, withGameContext } from 'context/GameContext';
import { useTeacherInGameInfo } from 'hooks';
import { useDisconnect } from 'hooks/game/useDisconnect';
import { isEmpty } from 'lodash';
import React, { memo, useMemo } from 'react';
import trans from 'translation';
import { KContainer, KLabel, KDims } from 'uikit';

const TeacherInGame = () => {
  const {
    roomInfo,
    showReviewingWords,
    reviewingTeam,
    setPreviousReviewingTeam,
    isReviewing,
    isCompleted,
    gameMode,
    isJumble,
    showJumbleStatistics
  } = useGameContext();

  const { runningTable, isPauseRequestor } = useTeacherInGameInfo();

  const status = runningTable?.status;

  const { tables, gameSetup } = roomInfo || {};

  const isWaiting = !tables || tables.length === 0;

  const renderContent = useMemo(() => {
    if (isCompleted) {
      return <Completed />;
    }

    if (isReviewing) {
      return <Reviewing />;
    }

    switch (status) {
      case TABLE_STATUSES.TERMINATED:
        return <KLabel.Text>This game has been terminated</KLabel.Text>;

      case TABLE_STATUSES.WAITING:
      case TABLE_STATUSES.WAITING_FOR_READY:
        if (gameMode === E_GAME_MODES.CHAOTIC.id) {
          return <Playing />;
        }
        return <WaitingForStart status={status} />;

      case TABLE_STATUSES.PLAYING:
        return <Playing />;

      case TABLE_STATUSES.PAUSING:
        return <Pausing isPauseRequestor={isPauseRequestor} />;

      default:
        return <WaitingRoom />;
    }
  }, [gameMode, isCompleted, isPauseRequestor, isReviewing, status]);

  const title = useMemo(() => {
    if (isEmpty(tables)) {
      return trans('common.waiting_room');
    }

    switch (status) {
      case TABLE_STATUSES.REVIEWING:
        return isJumble && showJumbleStatistics
          ? trans('common.student_submitted_answer')
          : showReviewingWords
          ? trans('common.game_result')
          : trans('common.make_the_decision');

      case TABLE_STATUSES.COMPLETED:
        return trans('common.game_finished');

      default:
        return `${gameSetup?.type || ''} ${trans('common.game')}`;
    }
  }, [
    isJumble,
    gameSetup?.type,
    showJumbleStatistics,
    showReviewingWords,
    status,
    tables
  ]);

  const isMobile = KDims.isMobile();

  useDisconnect();

  return (
    <KContainer.View flex>
      <KContainer.View paddingH={isMobile ? '1.5rem' : undefined}>
        <PageHeader
          title={title}
          titleTypo="TextLgBold"
          showBackButton={isWaiting || (isReviewing && !!reviewingTeam)}
          onClick={isReviewing ? () => setPreviousReviewingTeam() : undefined}
        />
      </KContainer.View>

      {renderContent}
    </KContainer.View>
  );
};

TeacherInGame.displayName = 'Screen.TeacherInGame';

export default memo(withGameContext(TeacherInGame));
